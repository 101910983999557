<template>
  <v-list nav>
    <v-list-item-group>
      <template>
        <v-list-item :to="{ name: 'Home' }">
          <v-list-item-action>
            <v-icon>keyboard_return</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $tc("pages.home") }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon>home</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-divider class="mb-2"></v-divider>
      </template>

      <v-list-item v-if="vueProdutos" :to="{ name: 'Produtos' }">
        <v-list-item-action>
          <v-icon>shopping_cart</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Produtos</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="vueProdutoClassificacoes"
        :to="{ name: 'ProdutoClassificacoes' }"
      >
        <v-list-item-action>
          <v-icon>bookmark</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Classificacoes</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="vueDepositos" :to="{ name: 'Depositos' }">
        <v-list-item-action>
          <v-icon>store</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Depositos</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MenuEstoque",
  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    vueProdutos() {
      return this.getAccess("vueProdutos", "menu");
    },
    vueProdutoClassificacoes() {
      return this.getAccess("vueProdutoClassificacoes", "menu");
    },
    vueDepositos() {
      return this.getAccess("vueDepositos", "menu");
    },
  },
};
</script>

<style></style>
